import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeScaleOptions,
  Tooltip,
  TooltipCallbacks,
  ActiveElement,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import AnnotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation'
import React, { forwardRef } from 'react'
import { Line } from 'react-chartjs-2'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { useTranslation } from 'react-i18next'

import { useCurrentUserLanguage } from '../../features/account/hooks/userHooks'
import { ChartDataFormat } from '../../features/update-history/types/types'
import { formatDate } from '../../helpers/date'
import MouseOutCatcherPlugin, { MouseOutCatcherOptions } from '../../plugins/chartjs/MouseOutCatcherPlugin'
import VerticalTooltipLinePlugin, { VerticalTooltipLineOptions } from '../../plugins/chartjs/VerticalTooltipLinePlugin'
import utilsService from '../../services/UtilsService'

/**
 * Component which draws a chart with timeline on its X-axis and variable values on its Y-axes. y1 describes the left Y-axis and y2 the right Y-axis.
 */

ChartJS.register(TimeScale, LinearScale, CategoryScale, PointElement, LineElement, Tooltip, AnnotationPlugin, Filler)

export type ScaleConfig = {
  dataFormat: ChartDataFormat
  title?: string
  reverse?: boolean
  shorten?: boolean
  precision?: number
  stacked?: boolean
  maxY?: number
  minY?: number
}

export type TimeScaleConfig = Partial<TimeScaleOptions>

enum YAxisId {
  Y1 = 'y1',
  Y2 = 'y2',
}

enum XAxisId {
  X = 'x',
}

export type TimelineChartMark<T> = {
  position: number
  data: T
}

export type TimelineChartProps<VerticalMarkType> = {
  data?: ChartData<'line'>
  scaleConfig?: {
    [YAxisId.Y1]?: ScaleConfig
    [YAxisId.Y2]?: ScaleConfig
    [XAxisId.X]?: TimeScaleConfig
  }
  verticalMarks?: TimelineChartMark<VerticalMarkType>[]
  highlightedVerticalMarks?: TimelineChartMark<VerticalMarkType>[]
  selectedVerticalMarks?: TimelineChartMark<VerticalMarkType>[]
  onVerticalMarkClicked?: (data: VerticalMarkType) => void
  tooltipCallbacks?: Partial<TooltipCallbacks<'line'>>
  chartLineOptions?: ChartOptions<'line'>
  onChartClick?: (xValue: number | undefined, yElements: ActiveElement[]) => void
  onHover?: (event: any, chartElement: ActiveElement[]) => void
  onMouseOut?: (event: any) => void
  hoverPropagation?: boolean
}

/**
 * Component representing the performance chart.
 *
 * NOTE: probably needs some layers of generalization as we go and figure out more use cases.
 */
export const TimelineChart = forwardRef<ChartJSOrUndefined<'line'>, TimelineChartProps<object>>(
  <VerticalMarkType extends object>(
    {
      data = { datasets: [] },
      scaleConfig,
      verticalMarks = [],
      highlightedVerticalMarks = [],
      selectedVerticalMarks = [],
      onVerticalMarkClicked,
      tooltipCallbacks = {},
      chartLineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
      },
      onChartClick,
      hoverPropagation = false,
      onHover,
      onMouseOut,
    }: TimelineChartProps<VerticalMarkType>,
    ref?: React.ForwardedRef<ChartJSOrUndefined<'line'>>
  ) => {
    const { t } = useTranslation()
    const userLanguage = useCurrentUserLanguage()

    let timeDisplayFormats

    switch (userLanguage) {
      case 'ja':
      case 'zh':
        timeDisplayFormats = {
          day: 'yyyy年M月d日',
          month: 'yyyy年M月',
        }
        break

      case 'en':
        timeDisplayFormats = {
          day: 'MMM d, yyyy',
          week: 'LL / yyyy',
          month: 'MMM, yyyy',
        }
        break
    }

    // create annotation objects for vertical marks
    const verticalAnnotations: AnnotationOptions<'point'>[] = verticalMarks.map((mark) => ({
      type: 'point',
      xValue: mark.position,
      yValue: (ctx) => (scaleConfig?.y1?.reverse ? ctx.chart.scales['y1'].max : ctx.chart.scales['y1'].min),
      yScaleID: 'y1',
      backgroundColor: 'rgb(224, 224, 224)',
      borderColor: 'rgb(187, 187, 187)',
      borderWidth: 2,
      radius: 6,
      enter: (ctx) => {
        if (onVerticalMarkClicked) {
          ctx.chart.canvas.style.cursor = 'pointer'
          ctx.element.options.borderColor = 'rgb(189, 101, 207)'
          ctx.chart.draw()
        }
      },
      leave: (ctx) => {
        if (onVerticalMarkClicked) {
          ctx.element.options.borderColor = 'rgb(187, 187, 187)'
          ctx.chart.canvas.style.cursor = 'default'
          ctx.chart.draw()
        }
      },
      click: () => onVerticalMarkClicked && onVerticalMarkClicked(mark.data),
    }))

    // create annotation objects for highlighted vertical marks
    const highlightedVerticalAnnotations = highlightedVerticalMarks.flatMap(
      (mark) =>
        [
          {
            type: 'point',
            xValue: mark.position,
            yValue: (ctx) => (scaleConfig?.y1?.reverse ? ctx.chart.scales['y1'].max : ctx.chart.scales['y1'].min),
            yScaleID: 'y1',
            backgroundColor: 'rgb(224, 224, 224)',
            borderColor: 'rgb(189, 101, 207)',
            borderWidth: 2,
            radius: 6,
            enter: (ctx) => {
              if (onVerticalMarkClicked) {
                ctx.chart.canvas.style.cursor = 'pointer'
                ctx.chart.draw()
              }
            },
            leave: (ctx) => {
              if (onVerticalMarkClicked) {
                ctx.chart.canvas.style.cursor = 'default'
                ctx.chart.draw()
              }
            },
            click: () => onVerticalMarkClicked && onVerticalMarkClicked(mark.data),
          },
        ] as AnnotationOptions[]
    )

    // create annotation objects for selected vertical marks
    const selectedVerticalAnnotations = selectedVerticalMarks.flatMap((mark) => {
      return [
        {
          type: 'line',
          scaleID: 'x',
          yScaleID: 'y1',
          value: mark.position,
          borderColor: 'rgb(150, 150, 150)',
          borderDash: [5, 5],
          borderWidth: 2,
        },
        {
          type: 'point',
          xValue: mark.position,
          yValue: (ctx) => (scaleConfig?.y1?.reverse ? ctx.chart.scales['y1'].max : ctx.chart.scales['y1'].min),
          yScaleID: 'y1',
          backgroundColor: 'rgb(224, 224, 224)',
          borderColor: 'rgb(189, 101, 207)',
          borderWidth: 2,
          radius: 6,
          enter: (ctx) => {
            if (onVerticalMarkClicked) {
              ctx.chart.canvas.style.cursor = 'pointer'
              ctx.chart.draw()
            }
          },
          leave: (ctx) => {
            if (onVerticalMarkClicked) {
              ctx.chart.canvas.style.cursor = 'default'
              ctx.chart.draw()
            }
          },
          click: () => onVerticalMarkClicked && onVerticalMarkClicked(mark.data),
        },
      ] as AnnotationOptions[]
    })

    const options: ChartOptions<'line'> & { plugins: { verticalTooltipLine?: VerticalTooltipLineOptions; mouseOutCatcher: MouseOutCatcherOptions } } = {
      ...chartLineOptions,
      onClick: (event, elements, chart) => {
        const x = elements?.[0]?.element.x || chart.tooltip?.caretX
        const xValue = x && chart.scales.x.getValueForPixel(x)
        onChartClick?.(xValue, elements)
      },
      onHover: (event: any, chartElement) => {
        if (event && onChartClick) {
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
        }

        if (event && hoverPropagation && onHover) {
          onHover(event, chartElement)
        }
      },
      scales: {
        x: {
          type: 'time' as const,
          time: {
            unit: 'day' as const,
            displayFormats: timeDisplayFormats,
            isoWeekday: true,
          },
          title: {
            display: true,
            align: 'center',
            text: t('common:date'),
            padding: { top: 16 },
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 16,
          },
          ...scaleConfig?.[XAxisId.X],
        },
        y1: {
          position: 'left' as const,
          beginAtZero: true,
          display: !!data?.datasets.find((dataset) => dataset.yAxisID === YAxisId.Y1),
          title: {
            text: scaleConfig?.y1?.title,
            display: !!scaleConfig?.y1?.title,
            padding: { bottom: 16 },
          },
          ticks: {
            precision: scaleConfig?.y1?.precision || 0,
            callback: (value, index, ticks) => {
              switch (scaleConfig?.y1?.dataFormat) {
                case ChartDataFormat.Currency:
                  return utilsService.formatCurrency(value as number, { shorten: scaleConfig?.y1?.shorten, mantissa: 2 })
                case ChartDataFormat.Percentage:
                  return utilsService.formatNumber(value as number, { mantissa: 0 }) + '%'
                default:
                  return utilsService.formatNumber(value as number, { shorten: scaleConfig?.y1?.shorten, mantissa: 2 })
              }
            },
          },
          reverse: scaleConfig?.y1?.reverse,
          stacked: scaleConfig?.y1?.stacked,
          suggestedMax: scaleConfig?.y1?.maxY,
          suggestedMin: scaleConfig?.y1?.minY,
        },
        y2: {
          position: 'right' as const,
          beginAtZero: true,
          display: !!data?.datasets.find((dataset) => dataset.yAxisID === YAxisId.Y2),
          title: {
            text: scaleConfig?.y2?.title,
            display: !!scaleConfig?.y2?.title,
            padding: { bottom: 16 },
          },
          ticks: {
            precision: scaleConfig?.y2?.precision || 0,
            callback: (value, index, ticks) => {
              switch (scaleConfig?.y2?.dataFormat) {
                case ChartDataFormat.Currency:
                  return utilsService.formatCurrency(value as number, { shorten: scaleConfig?.y2?.shorten, mantissa: 2 })
                case ChartDataFormat.Percentage:
                  return utilsService.formatNumber(value as number, { mantissa: 0 }) + '%'
                default:
                  return utilsService.formatNumber(value as number, { shorten: scaleConfig?.y2?.shorten, mantissa: 2 })
              }
            },
          },
          suggestedMax: scaleConfig?.y1?.maxY,
          reverse: scaleConfig?.y2?.reverse,
          stacked: scaleConfig?.y2?.stacked,
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          borderWidth: 2,
        },
      },
      interaction: {
        intersect: false,
        axis: 'x',
      },
      plugins: {
        legend: {
          position: 'bottom' as const,
          labels: {
            pointStyle: 'circle' as const,
            usePointStyle: true,
            padding: 16,
          },
        },
        tooltip: {
          position: 'average',
          yAlign: 'center',
          titleFont: {
            size: 14,
            weight: 'bolder' as const,
          },
          titleMarginBottom: 14,
          bodySpacing: 6,
          boxPadding: 6,
          usePointStyle: true,
          padding: 10,
          itemSort: (a, b) => {
            return a.element.y - b.element.y
          },
          callbacks: {
            title: (tooltipItems) => {
              return formatDate(tooltipItems[0].parsed.x, { language: userLanguage })
            },
            label: (tooltipItem) => {
              const yAxisID = tooltipItem.dataset.yAxisID as YAxisId
              const yAxisDataFormat = scaleConfig?.[yAxisID]?.dataFormat

              switch (yAxisDataFormat) {
                case ChartDataFormat.Currency:
                  return ` ${tooltipItem.dataset.label}: $${tooltipItem.formattedValue}`
                case ChartDataFormat.Percentage:
                  return ` ${tooltipItem.dataset.label}: ${tooltipItem.parsed.y.toFixed(1)}%`
                default:
                  return ` ${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`
              }
            },
            ...tooltipCallbacks,
          },
        },
        verticalTooltipLine: {
          color: '0, 0, 0',
          lineWidth: 2,
          lineDash: [5, 5],
        },
        annotation: {
          clip: false,
          annotations: [...verticalAnnotations, ...highlightedVerticalAnnotations, ...selectedVerticalAnnotations],
        },
        mouseOutCatcher: {
          onMouseOut: (event) => {
            if (onMouseOut) {
              onMouseOut(event)
            }
          },
        },
      },
    }

    return <Line ref={ref} data={data} options={options} plugins={[new VerticalTooltipLinePlugin(), new MouseOutCatcherPlugin()]} />
  }
)
