import { ActiveElement, TooltipCallbacks } from 'chart.js'
import { format } from 'date-fns'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { GameAndAnalysis } from '../../features/game/types/GameAndAnalysis'
import { resolveTimeunitByGranularity } from '../../features/revenue-and-downloads/helpers/helpers'
import { GranularityValue } from '../../features/revenue-and-downloads/types/Filters'
import { PerformanceChartColor } from '../../features/update-history/components/PerformanceChart/PerformanceChart'
import { useGameUpdatePerformanceData } from '../../features/update-history/hooks/useGameUpdatePerformanceData'
import { ChartDataFormat } from '../../features/update-history/types/types'
import { valueIsBetween } from '../../helpers/valueIsBetween'
import { useExportDataPerformanceChartV2 } from '../../hooks/exportDataHooks'
import { useError } from '../../hooks/useError'
import { useDataExport } from '../../hooks/useExportData'
import { DateRangeValue } from '../DateRangePicker/DateRangePicker'
import { TimelineChartVerticalMark } from '../GamePerformanceDialog/GamePerformanceDialog'
import { OverlayLoader } from '../OverlayLoader/OverlayLoader'
import { ScaleConfig, TimeScaleConfig, TimelineChart, TimelineChartMark } from '../TimelineChart/TimelineChart'
import { PerformanceChartV2DataType } from './PerformanceChartV2DataType'
import { PerformanceChartV2Filters } from './PerformanceChartV2Filters'

type PerformanceChartV2Props<VerticalMarkDataType extends TimelineChartVerticalMark<any>> = {
  gameAndAnalysis?: GameAndAnalysis
  appId: number
  marketIso: string
  dateRange?: DateRangeValue
  onDateRangeChanged?: (value?: DateRangeValue) => void
  granularity?: GranularityValue
  onGranularityChanged?: (value: GranularityValue) => void
  yAxisLeftConfig: YAxisConfig
  onYAxisLeftConfigChanged: (value: YAxisConfig) => void
  yAxisRightConfig: YAxisConfig
  onYAxisRightConfigChanged: (value: YAxisConfig) => void
  verticalMarks?: VerticalMarkDataType[]
  highlightedVerticalMarks?: VerticalMarkDataType[]
  selectedVerticalMarks?: VerticalMarkDataType[]
  onChartClick?: (xValue: number | undefined, yElements: ActiveElement[]) => void
  onMarkClicked?: (value: any) => void
  children?: ReactNode
}

export type YAxisConfig = {
  dataType: PerformanceChartV2DataType
  excludedDataTypes?: PerformanceChartV2DataType[] // data types to exclude. leave empty/undefined to show all
}

// data types are mapped to rolling days which affects calculations
const rollingDaysByDataType = {
  [PerformanceChartV2DataType.Revenue]: 1,
  [PerformanceChartV2DataType.Downloads]: 1,
  [PerformanceChartV2DataType.RevenueDownloadsRatio7Days]: 7,
  [PerformanceChartV2DataType.RevenueDownloadsRatio30Days]: 30,
  [PerformanceChartV2DataType.RevenueDownloadsRatio90Days]: 90,
  [PerformanceChartV2DataType.RevenueDownloadsRatioAllTime]: 0,
  [PerformanceChartV2DataType.Ranks]: 1,
  [PerformanceChartV2DataType.DAU]: 1,
  [PerformanceChartV2DataType.MAU]: 1,
}

export const PerformanceChartV2 = <VerticalMarkDataType extends TimelineChartVerticalMark<any>>({
  gameAndAnalysis,
  appId,
  marketIso,
  dateRange,
  granularity = GranularityValue.Day,
  onGranularityChanged,
  onDateRangeChanged,
  verticalMarks,
  highlightedVerticalMarks,
  selectedVerticalMarks,
  yAxisLeftConfig,
  onYAxisLeftConfigChanged,
  yAxisRightConfig,
  onYAxisRightConfigChanged,
  onChartClick,
  onMarkClicked,
}: PerformanceChartV2Props<VerticalMarkDataType>) => {
  const leftYAxisData = useGameUpdatePerformanceData({
    appId,
    marketIso,
    rollingDays: rollingDaysByDataType[yAxisLeftConfig.dataType],
    granularity,
  })

  const rightYAxisData = useGameUpdatePerformanceData({
    appId,
    marketIso,
    rollingDays: rollingDaysByDataType[yAxisRightConfig.dataType],
    granularity,
  })

  const exportableData = useGameUpdatePerformanceData({
    appId,
    marketIso,
    rollingDays: 1,
    granularity,
  })

  const error = leftYAxisData.error || rightYAxisData.error || exportableData.error
  useError({ error })

  const excludedDataTypes = [...(yAxisLeftConfig?.excludedDataTypes || []), ...(yAxisRightConfig?.excludedDataTypes || [])].filter(
    (value, index, self) => self.indexOf(value) === index
  )

  const { handleExportRequested, handleExport, exportRequested } = useDataExport()

  const exportData = useExportDataPerformanceChartV2(
    { appId, marketIso, dateRange, granularity, excludedDataTypes, data: exportableData },
    { skip: !exportRequested }
  )

  useEffect(() => {
    if (exportData && exportRequested) {
      handleExport({
        ...exportData,
        sheetName: 'Game Performance',
      })
    }
  }, [exportData, exportRequested, handleExport])

  const chartConfig = useYAxisConfig({
    axisConfigs: [
      { data: leftYAxisData, axisConfig: yAxisLeftConfig },
      { data: rightYAxisData, axisConfig: yAxisRightConfig },
    ],
    dateRange,
    granularity,
  })

  const tooltipCallbacks = useMemo(() => {
    return {
      title: (tooltipItems) => {
        switch (granularity) {
          case GranularityValue.Day:
            return format(tooltipItems[0].parsed.x, 'EEE, LLL d, yyyy')
          case GranularityValue.Week:
            return format(tooltipItems[0].parsed.x, 'I/RRRR')
          case GranularityValue.Month:
            return format(tooltipItems[0].parsed.x, 'MMM, yyyy')
        }
      },
    } as Partial<TooltipCallbacks<'line'>>
  }, [granularity])

  const minDate = Math.min(leftYAxisData.minDate, rightYAxisData.minDate)

  const filteredVerticalMarks = useMemo(() => verticalMarks?.map(markMapper).filter(markFilter(dateRange)), [dateRange, verticalMarks])
  const filteredHighlightedVerticalMarks = useMemo(
    () => highlightedVerticalMarks?.map(markMapper).filter(markFilter(dateRange)),
    [dateRange, highlightedVerticalMarks]
  )
  const filteredSelectedVerticalMarks = useMemo(() => selectedVerticalMarks?.map(markMapper).filter(markFilter(dateRange)), [dateRange, selectedVerticalMarks])

  const isLoading = leftYAxisData.isLoading || rightYAxisData.isLoading

  return (
    <Grid container wrap="wrap" spacing={2}>
      <Grid item xs={12}>
        <PerformanceChartV2Filters
          yAxisLeftConfig={yAxisLeftConfig}
          yAxisRightConfig={yAxisRightConfig}
          dateRange={dateRange}
          granularity={granularity}
          onGranularityChanged={onGranularityChanged}
          onYAxisLeftConfigChanged={onYAxisLeftConfigChanged}
          onYAxisRightConfigChanged={onYAxisRightConfigChanged}
          onDateRangeChanged={onDateRangeChanged}
          onExportFormatSelected={handleExportRequested}
          config={{ minDate }}
          isLoading={isLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <OverlayLoader isLoading={isLoading}>
          <TimelineChart
            chartLineOptions={{
              responsive: true,
              maintainAspectRatio: true,
              animation: false,
            }}
            data={{ datasets: chartConfig?.datasets }}
            scaleConfig={chartConfig.scaleConfig}
            tooltipCallbacks={tooltipCallbacks}
            verticalMarks={filteredVerticalMarks}
            highlightedVerticalMarks={filteredHighlightedVerticalMarks}
            selectedVerticalMarks={filteredSelectedVerticalMarks}
            onChartClick={onChartClick}
            onVerticalMarkClicked={onMarkClicked}
          />
        </OverlayLoader>
      </Grid>
    </Grid>
  )
}

const markMapper = <T extends TimelineChartVerticalMark<any>>(mark: T): TimelineChartMark<T> => ({
  position: +mark.timestamp,
  data: mark.data,
})
const markFilter =
  <T extends TimelineChartVerticalMark<any>>(dateRange: DateRangeValue | undefined) =>
  (mark: TimelineChartMark<T>) =>
    valueIsBetween(mark.position, dateRange?.fromDate?.getTime(), dateRange?.toDate?.getTime())

type ResolveYAxisConfigParams = {
  axisConfigs: { data: ReturnType<typeof useGameUpdatePerformanceData>; axisConfig: YAxisConfig }[]

  dateRange?: DateRangeValue
  granularity: GranularityValue
}

const useYAxisConfig = ({ axisConfigs, dateRange, granularity }: ResolveYAxisConfigParams) => {
  const { t } = useTranslation()
  const averageLabel = () => (granularity !== GranularityValue.Day ? `(${t('common:average_label')})` : '')
  const spanGapsByGranularity = (granularity: GranularityValue) => {
    switch (granularity) {
      case GranularityValue.Day:
        return 86400000
      case GranularityValue.Week:
        return 604800000
      case GranularityValue.Month:
        return 2592000000
    }
  }

  return {
    datasets: axisConfigs.flatMap((axisConfig, index) => {
      const {
        data,
        axisConfig: { dataType },
      } = axisConfig
      const rollingDays = rollingDaysByDataType[dataType]
      const axisId = `y${index + 1}`
      const color = index === 0 ? PerformanceChartColor.Primary : PerformanceChartColor.Secondary
      switch (dataType) {
        case PerformanceChartV2DataType.Revenue:
          return resolveDataset(
            axisId,
            `${t('common:revenue_text')} (${t('common:apple_ios')})`,
            color,
            ChartDataFormat.Currency,
            data.estimates?.map((estimate) => ({
              x: estimate.ts,
              y: estimate.revenue || 0,
            })),
            dateRange
          )
        case PerformanceChartV2DataType.Downloads:
          return resolveDataset(
            axisId,
            `${t('common:downloads_text')} (${t('common:apple_ios')})`,
            color,
            ChartDataFormat.Number,
            data.estimates?.map((estimate) => ({
              x: estimate.ts,
              y: estimate.downloads || 0,
            })),
            dateRange
          )
        case PerformanceChartV2DataType.RevenueDownloadsRatio7Days:
        case PerformanceChartV2DataType.RevenueDownloadsRatio30Days:
        case PerformanceChartV2DataType.RevenueDownloadsRatio90Days:
          return resolveDataset(
            axisId,
            `${t('common:revenue_downloads_ratio_days', { days: rollingDays })} (${t('common:apple_ios')})`,
            color,
            ChartDataFormat.Currency,
            data.estimates?.map((estimate) => ({
              x: estimate.ts,
              y: estimate.revenueAndDownloadsRatio || 0,
            })),
            dateRange
          )
        case PerformanceChartV2DataType.RevenueDownloadsRatioAllTime:
          return resolveDataset(
            axisId,
            `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
            color,
            ChartDataFormat.Currency,
            data.estimates?.map((estimate) => ({
              x: estimate.ts,
              y: estimate.revenueAndDownloadsRatio || 0,
            })),
            dateRange
          )
        case PerformanceChartV2DataType.Ranks:
          return [
            resolveDataset(
              axisId,
              `${t('common:top_grossing_rank')} ${averageLabel()}`,
              color,
              ChartDataFormat.Number,
              data.topGrossingRanks?.map((rank) => ({
                x: rank.ts,
                y: Math.round(rank.rank),
              })),
              dateRange,
              spanGapsByGranularity(granularity)
            ),
            resolveDataset(
              axisId,
              `${t('common:free_rank')} ${averageLabel()}`,
              PerformanceChartColor.Tertiary,
              ChartDataFormat.Number,
              data.freeRanks?.map((rank) => ({
                x: rank.ts,
                y: Math.round(rank.rank),
              })),
              dateRange,
              spanGapsByGranularity(granularity)
            ),
          ]
        case PerformanceChartV2DataType.DAU:
          return resolveDataset(
            axisId,
            `${t('common:dau')} ${averageLabel()}`,
            color,
            ChartDataFormat.Number,
            data.dailyActiveUsers?.map((dau) => ({
              x: dau.date,
              y: Math.round(dau.activeUsers || 0),
            })),
            dateRange
          )
        case PerformanceChartV2DataType.MAU:
          return {
            ...resolveDataset(
              axisId,
              t('common:mau'),
              color,
              ChartDataFormat.Number,
              data.monthlyActiveUsers?.map((mau) => ({
                x: mau.date,
                y: Math.round(mau.activeUsers || 0),
              })),
              dateRange
            ),
            pointRadius: granularity !== GranularityValue.Month ? 3 : 0,
          }
        default:
          return []
      }
    }),
    scaleConfig: {
      x: {
        time: {
          unit: resolveTimeunitByGranularity(granularity),
          isoWeekday: true,
        },
      } as TimeScaleConfig,
      ...axisConfigs.reduce((acc, axisConfig, index) => {
        const {
          axisConfig: { dataType },
        } = axisConfig
        const axisId = `y${index + 1}`
        const rollingDays = rollingDaysByDataType[dataType]
        switch (dataType) {
          case PerformanceChartV2DataType.Revenue:
            acc[axisId] = {
              dataFormat: ChartDataFormat.Currency,
              title: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
              shorten: true,
            }

            return acc
          case PerformanceChartV2DataType.Downloads:
            acc[axisId] = {
              dataFormat: ChartDataFormat.Number,
              title: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
              shorten: true,
            }

            return acc
          case PerformanceChartV2DataType.RevenueDownloadsRatio7Days:
          case PerformanceChartV2DataType.RevenueDownloadsRatio30Days:
          case PerformanceChartV2DataType.RevenueDownloadsRatio90Days:
            acc[axisId] = {
              dataFormat: ChartDataFormat.Currency,
              title: `${t('common:revenue_downloads_ratio_days', { days: rollingDays })} (${t('common:apple_ios')})`,
              shorten: true,
            }
            return acc
          case PerformanceChartV2DataType.RevenueDownloadsRatioAllTime:
            acc[axisId] = {
              dataFormat: ChartDataFormat.Currency,
              title: `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
              shorten: true,
            }

            return acc
          case PerformanceChartV2DataType.Ranks:
            acc[axisId] = {
              dataFormat: ChartDataFormat.Number,
              title: `${t('common:rank')} ${averageLabel()}`,
              reverse: true,
            }

            return acc
          case PerformanceChartV2DataType.DAU:
            acc[axisId] = {
              dataFormat: ChartDataFormat.Number,
              title: `${t('common:dau')} ${averageLabel()}`,
              shorten: true,
            }

            return acc
          case PerformanceChartV2DataType.MAU:
            acc[axisId] = {
              dataFormat: ChartDataFormat.Number,
              title: t('common:mau'),
              shorten: true,
            }

            return acc
          default:
            acc[axisId] = {}
            return acc
        }
      }, {} as { [axisdId: string]: ScaleConfig | {} }),
    },
  }
}

// helper for creating datasets for chart
export const resolveDataset = (
  axisId: string,
  label: string,
  color: string,
  tooltipFormat: ChartDataFormat,
  data: { x: number; y: number }[] = [],
  dateRange?: DateRangeValue,
  spanGaps?: number
) => {
  return {
    yAxisID: axisId,
    label: label,
    fill: false,
    spanGaps: spanGaps ? spanGaps : false,
    tension: 0.1,
    backgroundColor: color,
    borderColor: color,
    data: data.filter((item) => valueIsBetween(item.x, dateRange?.fromDate?.getTime(), dateRange?.toDate?.getTime())),
    tooltipFormat,
  }
}
